<template>
  <div class="clearfix">
    <b-overlay
      :show="loading"
      rounded="sm">
      <b-card>
        <div
          v-if="formHeaderVisible"
          class="row mb-0">
          <div class="col-xs-7 col-sm-8 col-md-8 col-lg-9">
            <h5 class="page-title mb-0">
              <feather-icon
                :icon="formIcon"
                size="17"/> {{ formSubtitle }} <small class="text-primary">{{ title }}</small>
            </h5>
          </div>
          <div class="col-xs-5 col-sm-4 col-md-4 col-lg-3"/>
        </div>

        <hr
          v-if="formHeaderVisible"
          class="mt-1 mb-1">

        <dx-form
          :ref="formName"
          :form-data="formData"
          :on-field-data-changed="onFormFieldDataChanged"
          :element-attr="elementAttr"
          :on-editor-enter-key="onEditorEnterKeyCallback">

          <template
            v-for="(slot) in formSlot"
            :slot="slot"
            slot-scope="data">
            <slot
              :name="slot"
              v-bind="data"/>
          </template>

        </dx-form>

        <slot name="additionalForm"/>

        <hr class="mt-1 mb-2">

        <div
          v-show="formItemsSetStatus"
          :class="submitBtnClass">
          <button
            v-if="btnSubmitVisible"
            :disabled="loading"
            class="btn btn-primary mr-1 text-uppercase"
            title=""
            @click.prevent="submitFormProcess">
            Save
          </button>
          <button
            v-if="(formDataId===null || formCloneMode) && btnSubmitVisible && btnSubmitAndNewVisible"
            :disabled="loading"
            class="btn btn-success mr-1 text-uppercase"
            title=""
            @click.prevent="submitFormProcess(null, true)">
            Save & Add New
          </button>
          <button
            v-if="formDataId!==null && !formCloneMode && btnSubmitVisible"
            :disabled="loading"
            class="btn btn-success mr-1 text-uppercase"
            title=""
            @click.prevent="submitFormProcess(null, false, true)">
            Save & Close
          </button>
          <button
            v-if="cancelBtnVisible"
            :disabled="loading"
            class="btn btn-warning mr-1 text-uppercase"
            title=""
            @click.prevent="resetForm">
            Cancel
          </button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { stateMerge } from 'vue-object-merge'
import { DefaultFormData } from '@/modules/mixins/data'
import { nextTick } from '@vue/composition-api'
import axiosIns from '../../libs/axios'

export default {
  components: {
  },
  props: {
    formName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    formDataId: {
      default: null
    },
    onFormClose: {
      type: String,
      default: null,
      required: true
    },
    onFormFieldDataChanged: {
      type: Function,
      default: function _default() {
        return false
      }
    },
    onFormAddNew: {
      type: String,
      default: null
    },
    onFormShow: {
      type: String,
      default: null
    },
    onFormAfterSave: {
      type: String,
      default: null
    },
    onFormBeforeSave: {
      type: Function,
      default: function _default() {
        // eslint-disable-next-line no-unused-vars
        return new Promise((done, fail) => {
          done(true)
        })
      }
    },
    editorEnterKeySubmitVoid: {
      type: Array,
      default: function _default() {
        return []
      }
    },
    formSlot: {
      type: Array,
      default: function _default() {
        return []
      }
    },
    formCloneMode: {
      type: Boolean,
      default: false
    },
    hasUploadFiles: {
      type: Boolean,
      default: false
    },
    btnSubmitVisible: {
      type: Boolean,
      default: true
    },
    btnSubmitAndNewVisible: {
      type: Boolean,
      default: true
    },
    cancelBtnVisible: {
      type: Boolean,
      default: true
    },
    formHeaderVisible: {
      type: Boolean,
      default: true
    },
    elementAttr: {
      type: Object,
      default: null
    },
    submitBtnClass: {
      type: String,
      default: 'float-left'
    }
  },
  data() {
    const data = { ...DefaultFormData }
    stateMerge(data, {
      formData: {}
    })
    return data
  },
  computed: {},
  mounted() {
    const vm = this
    // vm.unbindMousetrap()
    // vm.bindMoustrapForm(this)
    vm.loading = true
    vm.form = vm.$refs[vm.formName].$_instance

    axiosIns.get(`${this.formSettingRouteReq + this.formName}.json`, { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' } })
      .then(resp => {
        try {
          if (resp.data === undefined || resp.status === 404) {
            throw 'form configuration not found'
          } else if (resp.data.data.formActionRoute === undefined) {
            throw 'formActionRoute configuration not found'
          }
          vm.formConfigInit(resp.data.data)
        } catch (e) {
          vm.msgShow(e, 'error')
        }
      })
      .then(() => {
        if (vm.formDataId !== null) {
          const route = vm.formConfig.formActionRouteEdit !== undefined ? `/${vm.formConfig.formActionRouteEdit}/${vm.formDataId}` : `/${vm.formActionRoute}/${vm.formDataId}/edit`
          axiosIns.get(route)
            .then(resp => {
              if (resp.data.success && resp.data.data !== undefined) {
                vm.formDataEdit = resp.data.data
              } else {
                vm.formDataEdit = resp.data
              }
            }).then(() => {
              vm.formMounted()
            }).catch(error => {
              vm.loading = false
              if (error.response.data !== undefined) {
                vm.msgShow(error.response.data.message, 'error')
              }
              vm.$events.fire(vm.onFormClose)
            })
        } else {
          vm.formMounted()
        }
      })
      .then(() => {
        if (vm.onFormShow !== null) {
          vm.$events.fire(vm.onFormShow)
        }
      })
      .catch(error => {
        console.log(error)
        vm.loading = false
        if (error.status === 404) {
          vm.msgShow('form configuration not found', 'error')
        } else if (error.response.data !== undefined) {
          vm.msgShow(error.response.data.message, 'error')
        }
        vm.$events.fire(vm.onFormClose)
      })
  },
  beforeDestroy() {
    // this.unbindMousetrap()
  },
  methods: {
    submitFormProcess(e, addNew = false, closeForm = false) {
      const vm = this
      vm.loading = true
      vm.setBlur()

      new Promise((done, fail) => {
        let valid = true
        const form = vm.$refs[vm.formName].$_instance
        const checkForm = form.validate()
        if (checkForm !== undefined) {
          valid = checkForm.isValid
        }
        console.log('valid', valid)
        if (valid) {
          return done(true)
        }
        vm.loading = false
        console.log('checkForm', checkForm)
        console.log('validator', checkForm.brokenRules[0].validator.instance())
        if (checkForm !== undefined) {
          checkForm.brokenRules[0].validator.instance().focus()
        }
        return fail('Please correct the field(s) and try again')
      }).then(() => vm.onFormBeforeSave().then(resp => {
        console.log('onFormBeforeSave', resp)
        if (resp) {
          return true
        }
        vm.loading = false
        return false
      })).then(resp => {
        console.log('after onFormBeforeSave', resp)
        if (resp) {
          const fd = (vm.hasUploadFiles) ? vm.formDataHasUploadFiles : vm.formData
          let method = vm.formMethod.toLowerCase()
          if (vm.hasUploadFiles) {
            if (vm.formMethod === 'PUT') {
              method = 'post'
              fd.append('_method', 'put')
            }
          }
          vm.formValidate(vm.formName, method, vm.formAction, fd, vm.hasUploadFiles)
            // eslint-disable-next-line no-shadow
            .then(resp => {
              vm.loading = false

              if (vm.onFormAfterSave !== null) {
                vm.$events.fire(vm.onFormAfterSave, resp.data)
              }

              if (addNew || (vm.formDataId !== null && !vm.formCloneMode && !closeForm)) {
                vm.hasSubmitData = true
                if (addNew) {
                  nextTick().then(() => {
                    if (vm.formConfig.formDataInit !== undefined) {
                      vm.formData = JSON.parse(JSON.stringify(vm.formConfig.formDataInit))
                      // vm.formData = Object.assign({}, vm.formConfig.formDataInit)
                    }

                    if (vm.focusField !== undefined && vm.focusField !== null) {
                      vm.focusField.focus()
                    }

                    if (vm.onFormAddNew) {
                      vm.$events.fire(vm.onFormAddNew)
                    }
                  }).then(() => {
                    vm.form.resetValues()
                  })
                } else {
                  vm.focusField.focus()
                }
              } else {
                vm.$events.fire(vm.onFormClose, true)
              }
            })
            .catch(error => {
              vm.formNotValid(error, e)
            })
        }
      }).catch(resp => {
        console.log('resp', resp.response)
        vm.msgShow(resp, 'error')
        vm.loading = false
      })
    }
  },
  events: {}
}
</script>
