import store from '@/store'
// import axiosIns from '@/libs/axios'
//
// async function hello() {
//   // eslint-disable-next-line no-unused-vars
//   const data = await axiosIns.get('userMenu').then(response => {
//     console.log('await response', response.data.data)
//     return response.data.data
//   })
//
//   return data
// }
//
// console.log('opop', localStorage.getItem('userData'))
// export default hello
// export default hello().then(result => {
//   console.log('hello', result) // "Some User token"
//   return result
// })

console.log('store', store.getters['verticalMenu/getMenu'])
export default store.getters['verticalMenu/getMenu']
// export default [
//   {
//     title: 'Home',
//     route: 'home',
//     icon: 'HomeIcon',
//     file_component: 'Home',
//     action: 'index',
//     resource: 'home'
//   },
//   {
//     title: 'Admin',
//     icon: 'GitlabIcon',
//     action: 'index',
//     resource: 'admin',
//     children: [
//       {
//         title: 'User',
//         route: 'user.index',
//         file_component: 'User',
//         action: 'index',
//         resource: 'user'
//       },
//       {
//         title: 'Menu',
//         route: 'menu.index',
//         file_component: 'Menu',
//         action: 'index',
//         resource: 'menu'
//       },
//       {
//         title: 'Role',
//         route: 'role.index',
//         file_component: 'Role',
//         action: 'index',
//         resource: 'role'
//       },
//       {
//         title: 'Permission',
//         route: 'permission.index',
//         file_component: 'Permission',
//         action: 'index',
//         resource: 'permission'
//       },
//       {
//         title: 'Form Setting',
//         route: 'formSetting.index',
//         file_component: 'FormSetting',
//         action: 'index',
//         resource: 'formSetting'
//       }
//     ]
//   },
//   {
//     title: 'Master Data',
//     icon: 'MenuIcon',
//     children: [
//       {
//         title: 'Distrik Viking',
//         route: 'distrik',
//         file_component: 'Distrik',
//         action: 'index',
//         resource: 'distrik'
//       }
//     ]
//   },
//   {
//     title: 'Keanggotaan',
//     route: 'keanggotaan.index',
//     icon: 'UsersIcon',
//     file_component: 'Keanggotaan',
//     action: 'index',
//     resource: 'keanggotaan'
//   },
//   {
//     title: 'Access Control',
//     route: 'third-page',
//     icon: 'ShieldIcon',
//     file_component: 'ThirdPage',
//     // acl: {
//     action: 'read',
//     resource: 'ACL'
//     // }
//   }
// ]
