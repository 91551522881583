import Vue from 'vue'

// axios
import axios from 'axios'
import https from 'https'

// const fs = require('fs')
// const path = require('path')

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

// const path = require('path')

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  withCredentials: false,
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
    common: {
      'Access-Control-Allow-Origin': '*'
    }
  }
})

if (process.env.NODE_ENV === 'development') {
  const httpsAgent = new https.Agent({
    rejectUnauthorized: false
  })
  axiosIns.defaults.options = httpsAgent
  // eslint-disable-next-line no-console
  // console.log(process.env.NODE_ENV, 'RejectUnauthorized is disabled.')
  // console.log(process.env.VUE_APP_API_URL, 'MAIN_API_URL.')
}

Vue.prototype.$http = axiosIns

export default axiosIns
