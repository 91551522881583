<template>
  <div class="mb-2">
    <slot name="toolbarSlotFirst"/>

    <div class="row mb-1">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9 grid-toolbar">
        <slot name="toolbarSlotBefore"/>

        <b-dropdown
          v-if="selectedRowsDropdownVisible"
          id="dropdown-1"
          :ref="gridRefName+'SelectedRowsButton'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :text="`${selectedRowsCount} DATA SELECTED`"
          variant="primary"
          size="sm">
          <slot name="selectedRowsCommandItem"/>
        </b-dropdown>

        <b-button-group
          v-if="actionButtonGroupVisible"
          size="sm"
        >
          <b-button
            v-if="false"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :disabled="selectedRowsCount===0"
            title="Clear Selection"
            variant="primary"
            @click="clearSelectionClicked()"
          >
            <feather-icon
              icon="MinusIcon"
              size="12" />
          </b-button>
          <b-button
            v-if="refreshBtnVisible"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            title="Reload"
            variant="primary"
            @click="refreshGrid()"
          >
            <feather-icon
              icon="RefreshCwIcon"
              size="12" />
          </b-button>
          <b-button
            v-if="clearFilterBtnVisible"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            title="Clear Filter"
            variant="primary"
            @click="clearFilterClicked()"
          >
            <feather-icon
              icon="FilterIcon"
              size="12" />
          </b-button>
          <b-button
            v-if="columnChooserVisible"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            title="Column Chooser"
            variant="primary"
            @click="showColumnChooserClicked()"
          >
            <feather-icon
              icon="ColumnsIcon"
              size="12" />
          </b-button>
          <b-button
            v-if="trashBtnVisible"
            :ref="gridRefName+'TrashBtn'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            title="Trash"
            variant="primary"
            @click="trashBtnClicked()"
          >
            <feather-icon
              icon="Trash2Icon"
              size="12"/>
          </b-button>
        </b-button-group>

        <slot name="toolbarSlot"/>

        <b-dropdown
          v-if="exportBtnVisible"
          :ref="gridRefName+'ExportButton'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          text="EXPORT"
          size="sm">
          <b-dropdown-item
            @click="exportGridClicked('0')">
            <span class="text-uppercase">Only Selected Data</span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="exportGridClicked('1')">
            <span class="text-uppercase">All Data</span>
          </b-dropdown-item>
        </b-dropdown>

        <b-button-toolbar
          v-if="searchInputVisible"
          size="sm"
          style="display: inline-flex; vertical-align: middle;">
          <b-input-group size="sm">
            <b-form-input
              v-model="search"
              placeholder="search..."
              size="sm"
              class="input-focus-border-visible"/>
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="searchProcess">
                <feather-icon
                  icon="SearchIcon"
                  size="12" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-button-toolbar>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
        <h4
          v-if="pageTitleVisible"
          class="page-title mb-0 float-right mr-2 mt-1"
          :style="titleStyle">
          <feather-icon
            icon="MonitorIcon"
            size="20"
          />
          {{ title }}
          <small class="text-primary">{{ subtitle }}</small>
        </h4>
      </div>
    </div>

    <slot name="toolbarSlotSecond"/>
  </div>
</template>

<script>

import { nextTick } from '@vue/composition-api'

export default {
  name: 'GridToolbar',
  components: {},
  props: {
    title: {
      type: String,
      required: true
    },
    titleStyle: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    pageName: {
      type: String,
      required: true
    },
    actionButtonGroupVisible: {
      type: Boolean,
      default: true
    },
    pageTitleVisible: {
      type: Boolean,
      default: true
    },
    gridRefName: {
      type: String,
      required: true
    },
    selectedRowsDropdownVisible: {
      type: Boolean,
      default: true
    },
    selectedRowsData: {
      type: Array,
      required: false,
      default: function _default() {
        return []
      }
    },
    columnChooserVisible: {
      type: Boolean,
      default: true
    },
    trashBtnVisible: {
      type: Boolean,
      default: false
    },
    searchInputVisible: {
      type: Boolean,
      default: true
    },
    searchVisible: {
      type: Boolean,
      default: true
    },
    exportBtnVisible: {
      type: Boolean,
      default: true
    },
    refreshBtnVisible: {
      type: Boolean,
      default: true
    },
    clearFilterBtnVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      search: '',
      trashBtnActive: false
    }
  },
  computed: {
    selectedRowsCount() {
      return this.selectedRowsData.length
    },
    grid() {
      return this.$parent.$refs[this.gridRefName].instance
    },
    trashBtnType() {
      return this.trashBtnActive ? 'danger' : 'default'
    }
  },
  watch: {
    dataSelected(val) {
      // eslint-disable-next-line no-return-assign
      return this.rowSelectedInfo = `${val.length} data selected`
    }
  },
  beforeDestroy() {
  },
  methods: {
    refreshGrid() {
      console.log('refreshGrid')
      this.$events.fire(`${this.pageName}GridRefreshEvent`)
    },
    selectedRowsCommand(command) {
      console.log('asd', command)
      this.$parent.selectedRowsCommand(command, this.selectedRowsData)
    },
    // eslint-disable-next-line consistent-return
    exportGridClicked(command) {
      nextTick().then(() => {
        const allData = command === '1'
        if (!allData && this.grid.getSelectedRowsData().length <= 0) {
          this.msgShow('No data selected yet', 'warning')
          this.grid.endCustomLoading()
          return false
        }
        this.grid.exportToExcel(!allData)
      })
    },
    clearSelectionClicked() {
      this.grid.clearSelection()
      const index = this.grid.option('focusedRowIndex')
      this.grid.focus(this.grid.getCellElement(index, 2))
    },
    clearFilterClicked() {
      this.search = ''
      this.grid.clearFilter()
      const index = this.grid.option('focusedRowIndex')
      this.grid.focus(this.grid.getCellElement(index, 2))
    },
    showColumnChooserClicked() {
      this.grid.showColumnChooser()
    },
    trashBtnClicked() {
      this.trashBtnActive = !this.trashBtnActive
      this.$parent.trashedMode = this.trashBtnActive
      const dataSource = this.grid.option('dataSource')
      console.log('this', this)
      const trash = this.trashBtnActive ? { trashed: true } : { trashed: false }
      const loadParams = { ...dataSource.loadParams, ...trash }

      console.log('loadParams', loadParams)
      const db = this.$aspnet.createStore({
        key: dataSource.id,
        loadUrl: dataSource.loadUrl,
        loadParams,
        onBeforeSend(operation, ajaxSettings) {
          if (operation === 'load') {
            ajaxSettings.headers = {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
            ajaxSettings.data = {
              data: JSON.stringify(ajaxSettings.data)
            }
          }
        }
      })
      this.grid.clearFilter()
      this.grid.clearSorting()
      this.grid.option('dataSource.store', db)
    },
    searchProcess() {
      const vm = this
      if (this.search.trim().length > 0) {
        const filterFields = []
        const fields = vm.grid.getVisibleColumns().filter(o => o.calculateSortValue !== undefined || o.allowFiltering === true).map(o => {
          if (vm.$parent[o.calculateFilterExpressionMethod] !== undefined && typeof vm.$parent[o.calculateFilterExpressionMethod] === 'function') {
            return vm.$parent[o.calculateFilterExpressionMethod](vm.search.trim(), 'contains', o.dataField)
          }
          if (o.calculateSortValue !== undefined && !o.ignoreFilterBycalculateSortValue) {
            return [o.calculateSortValue, 'contains', vm.search.trim()]
          }
          return [o.dataField, 'contains', vm.search.trim()]
        })

        fields.forEach((val, i) => {
          filterFields.push(val)
          if (i !== fields.length - 1) {
            filterFields.push('or')
          }
        })
        vm.grid.filter(filterFields)
      } else {
        vm.grid.clearFilter()
      }

      setTimeout(() => {
        vm.grid.focus(vm.grid.getCellElement(vm.$parent.focusedRowIndex, 2))
      }, 100)
    }
  }
}
</script>

<style scoped>

</style>
