import Vue from 'vue'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
    // Will be used by this service for making API calls
    axiosIns = null

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // For Refreshing Token
    subscribers = []

    constructor(axiosIns, jwtOverrideConfig) {
      this.axiosIns = axiosIns
      this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

      const vm = new Vue()

      // Request Interceptor
      this.axiosIns.interceptors.request.use(
        config => {
          // Get token from localStorage
          const accessToken = this.getToken()

          // If token is present add it to request's Authorization Header
          if (accessToken) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          }
          return config
        },
        error => Promise.reject(error)
      )

      // Add request/response interceptor
      this.axiosIns.interceptors.response.use(
        response => response,
        error => {
          // const { config, response: { status } } = error
          const { config, response } = error
          const originalRequest = config

          // if (status === 401) {
          if (response && response.status === 401) {
            if (this.getRefreshToken() !== null) {
              if (!this.isAlreadyFetchingAccessToken) {
                this.isAlreadyFetchingAccessToken = true

                this.refreshToken()
                  .then(r => {
                    if (r.data.status === 'error') {
                      localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
                      localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
                      localStorage.removeItem('userData')
                      window.location.reload()
                    }
                    this.isAlreadyFetchingAccessToken = false

                    // Update accessToken in localStorage
                    this.setToken(r.data.accessToken)
                    this.setRefreshToken(r.data.refreshToken)

                    this.onAccessTokenFetched(r.data.accessToken)
                  })
              }
              const retryOriginalRequest = new Promise(resolve => {
                this.addSubscriber(accessToken => {
                  // Make sure to assign accessToken according to your response.
                  // Check: https://pixinvent.ticksy.com/ticket/2413870
                  // Change Authorization header
                  originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                  resolve(this.axiosIns(originalRequest))
                })
              })
              return retryOriginalRequest
            }
          } else {
            console.log('jwtService error', error)
            const { message, err } = error.response.data
            if (!error.response) {
              vm.$events.fire('showNotif', { msg: 'Error: Network Error', variant: 'danger' })
            } else if (message !== undefined) {
              vm.$events.fire('showNotif', { msg: message, variant: 'danger' })
            } else if (err !== undefined) {
              vm.$events.fire('showNotif', { msg: err, variant: 'danger' })
            } else {
              vm.$events.fire('showNotif', { msg: error.response.statusText, variant: 'danger' })
            }
            return Promise.reject(error)
          }
        }
      )
    }

    onAccessTokenFetched(accessToken) {
      this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
      this.subscribers.push(callback)
    }

    getToken() {
      return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    getRefreshToken() {
      return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
    }

    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    setToken(value) {
      localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    setRefreshToken(value) {
      localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    login(...args) {
      return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
    }

    logout() {
      return this.axiosIns.post(this.jwtConfig.logoutEndpoint)
    }

    user() {
      return this.axiosIns.get(this.jwtConfig.getUserEndpoint)
    }

    register(...args) {
      return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
    }

    refreshToken() {
      if (this.getRefreshToken() !== null) {
        return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
          refresh_token: this.getRefreshToken(),
          grant_type: 'refresh_token',
          client_id: process.env.VUE_APP_PASSPORT_CLIENT_ID,
          client_secret: process.env.VUE_APP_PASSPORT_CLIENT_SECRET
        })
      }
      return false
    }
}
