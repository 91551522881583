window.numeral = require('numeral')

window.numeral.register('locale', 'id', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  currency: {
    symbol: 'Rp'
  }
})

window.numeral.locale('id')
