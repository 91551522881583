export default {
  // Endpoints
  // loginEndpoint: '/auth/login',
  loginEndpoint: '/oauth/token',
  // loginEndpoint: '/login',
  registerEndpoint: '/auth/register',
  refreshEndpoint: '/oauth/token/refresh',
  // logoutEndpoint: '/auth/logout',
  logoutEndpoint: '/logout',
  getUserEndpoint: '/user',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
