import Vue from 'vue'
import GridToolbar from '@/views/components/GridToolbar.vue'
import AuditGrid from '@/views/components/AuditGrid.vue'
import FormApp from '@/views/components/FormApp.vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {
  BDropdown, BDropdownItem, BButtonGroup, BButton, BInputGroup, BFormInput, BInputGroupAppend, BButtonToolbar, BCard, BAvatar, BOverlay, BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  DxDataGrid,
  DxColumn,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxLookup,
  DxFormat,
  DxColumnChooser,
  DxSorting,
  DxEditing,
  DxSelection,
  DxRowDragging,
  DxScrolling,
  DxMasterDetail
} from 'devextreme-vue/ui/data-grid'
import { DxForm, DxItem } from 'devextreme-vue/ui/form'
import { DxValidationRule, DxValidator } from 'devextreme-vue/ui/validator'
import { DxCheckBox } from 'devextreme-vue/ui/check-box'
import { DxSwitch } from 'devextreme-vue/ui/switch'
import { DxTagBox } from 'devextreme-vue/ui/tag-box'
import { DxTextBox } from 'devextreme-vue/ui/text-box'
import { DxTextArea } from 'devextreme-vue/ui/text-area'
import { DxNumberBox } from 'devextreme-vue/ui/number-box'
import { DxSelectBox } from 'devextreme-vue/ui/select-box'
import { DxDateBox } from 'devextreme-vue/ui/date-box'

Vue.component('GridToolbar', GridToolbar)
Vue.component('AuditGrid', AuditGrid)
Vue.component('FormApp', FormApp)
Vue.component('BCard', BCard)
Vue.component('BDropdown', BDropdown)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BButtonGroup', BButtonGroup)
Vue.component('BButton', BButton)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BFormInput', BFormInput)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BButtonToolbar', BButtonToolbar)
Vue.component('BAvatar', BAvatar)
Vue.component('BOverlay', BOverlay)
Vue.component('BBadge', BBadge)
Vue.directive('Ripple', Ripple)

// Vue.component('DxList', DxList)
Vue.component('DxForm', DxForm)
Vue.component('DxItem', DxItem)
Vue.component('DxCheckBox', DxCheckBox)
Vue.component('DxSwitch', DxSwitch)
Vue.component('DxTagBox', DxTagBox)
Vue.component('DxValidationRule', DxValidationRule)
Vue.component('DxValidator', DxValidator)

Vue.component('DxDataGrid', DxDataGrid)
Vue.component('DxColumn', DxColumn)
Vue.component('DxPager', DxPager)
Vue.component('DxPaging', DxPaging)
Vue.component('DxFilterRow', DxFilterRow)
Vue.component('DxLookup', DxLookup)
Vue.component('DxFormat', DxFormat)
Vue.component('DxColumnChooser', DxColumnChooser)
Vue.component('DxSorting', DxSorting)
Vue.component('DxEditing', DxEditing)
Vue.component('DxSelection', DxSelection)
Vue.component('DxTextBox', DxTextBox)
Vue.component('DxTextArea', DxTextArea)
Vue.component('DxNumberBox', DxNumberBox)
Vue.component('DxSelectBox', DxSelectBox)
Vue.component('DxDateBox', DxDateBox)
Vue.component('DxRowDragging', DxRowDragging)
Vue.component('DxScrolling', DxScrolling)
Vue.component('DxMasterDetail', DxMasterDetail)

Vue.component(FeatherIcon.name, FeatherIcon)
