import Vue from 'vue'
import 'mousetrap'
import 'mousetrap/plugins/global-bind/mousetrap-global-bind'

Vue.mixin({
  methods: {
    bindMoustrapDetailPage(detailComponent = null) {
      let vm = this
      if (detailComponent !== null) {
        vm = detailComponent
      }
      this.$mousetrap.bind('esc', e => {
        e.preventDefault()
        e.stopPropagation()
        vm.back()
        return false
      })
      this.$mousetrap.bind('f1', e => {
        e.preventDefault()
        e.stopPropagation()

        if (vm.$refs.UpdateButton !== undefined) {
          vm.$refs.UpdateButton.show()
          vm.$refs.UpdateButton.focus()
        }

        return false
      })
      this.$mousetrap.bind('f2', e => {
        e.preventDefault()
        e.stopPropagation()
        vm.edit()
        return false
      })
      this.$mousetrap.bind('f3', e => {
        e.preventDefault()
        e.stopPropagation()
        vm.print()
        return false
      })

      this.bindMoustrapTabChange()
    },
    bindMoustrapForm(formComponent) {
      this.$mousetrap.bindGlobal('f1', e => {
        e.preventDefault()
        e.stopPropagation()
        formComponent.submitFormProcess()
        return false
      })
      this.$mousetrap.bindGlobal('f3', e => {
        e.preventDefault()
        e.stopPropagation()
        formComponent.resetForm()
        return false
      })
      if (formComponent.formMethod === 'POST') {
        this.$mousetrap.bindGlobal('f2', e => {
          e.preventDefault()
          e.stopPropagation()
          formComponent.submitFormProcess(null, true)
          return false
        })
      } else {
        this.$mousetrap.unbind('f2')
      }

      this.bindMoustrapTabChange()
    },
    bindMoustrapDataGrid(ref) {
      const vm = this
      this.$mousetrap.bind('f1', e => {
        e.preventDefault()
        e.stopPropagation()

        if (vm.$refs[ref].$refs[`${ref }SelectedRowsButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].show()
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].focus()
        }

        if (vm.$refs[ref].$refs[`${ref }ExportButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }ExportButton`].hide()
        }
        return false
      })
      this.$mousetrap.bind('f2', e => {
        e.preventDefault()
        e.stopPropagation()
        vm.$refs[ref].$refs[ref].instance.clearSelection()

        if (vm.$refs[ref].$refs[`${ref }SelectedRowsButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].hide()
        }

        if (vm.$refs[ref].$refs[`${ref }ExportButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }ExportButton`].hide()
        }

        vm.focusRowGrid(ref)
        return false
      })
      this.$mousetrap.bind('f3', e => {
        e.preventDefault()
        e.stopPropagation()
        vm.$refs[ref].addNew()

        if (vm.$refs[ref].$refs[`${ref }SelectedRowsButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].hide()
        }

        if (vm.$refs[ref].$refs[`${ref }ExportButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }ExportButton`].hide()
        }

        return false
      })
      this.$mousetrap.bind('f4', e => {
        e.preventDefault()
        e.stopPropagation()
        vm.$refs[ref].refreshGrid()

        if (vm.$refs[ref].$refs[`${ref }SelectedRowsButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].hide()
        }

        if (vm.$refs[ref].$refs[`${ref }ExportButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }ExportButton`].hide()
        }
        return false
      })
      this.$mousetrap.bind('f5', e => {
        e.preventDefault()
        e.stopPropagation()
        vm.$refs[ref].$refs[ref].instance.clearFilter()
        vm.$refs[ref].search = ''

        if (vm.$refs[ref].$refs[`${ref }SelectedRowsButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].hide()
        }

        if (vm.$refs[ref].$refs[`${ref }ExportButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }ExportButton`].hide()
        }
        vm.focusRowGrid(ref)
        return false
      })
      this.$mousetrap.bind('f6', e => {
        e.preventDefault()
        e.stopPropagation()
        vm.$refs[ref].$refs[ref].instance.showColumnChooser()

        if (vm.$refs[ref].$refs[`${ref }SelectedRowsButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].hide()
        }

        if (vm.$refs[ref].$refs[`${ref }ExportButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }ExportButton`].hide()
        }
        return false
      })
      this.$mousetrap.bind('f7', e => {
        e.preventDefault()
        e.stopPropagation()

        if (vm.$refs[ref].$refs[`${ref }SelectedRowsButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].hide()
        }

        if (vm.$refs[ref].$refs[`${ref }ExportButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }ExportButton`].show()
          vm.$refs[ref].$refs[`${ref }ExportButton`].focus()
        }
        return false
      })
      this.$mousetrap.bind('f8', e => {
        e.preventDefault()
        e.stopPropagation()

        if (vm.$refs[ref].$refs[`${ref }SearchInput`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SearchInput`].focus()
        }

        if (vm.$refs[ref].$refs[`${ref }SelectedRowsButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].hide()
        }

        if (vm.$refs[ref].$refs[`${ref }ExportButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }ExportButton`].hide()
        }
        return false
      })
      this.$mousetrap.bind('fn+f', e => {
        e.preventDefault()
        e.stopPropagation()

        if (vm.$refs[ref].$refs[`${ref }SelectedRowsButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }SelectedRowsButton`].hide()
        }

        if (vm.$refs[ref].$refs[`${ref }ExportButton`] !== undefined) {
          vm.$refs[ref].$refs[`${ref }ExportButton`].hide()
        }
        vm.focusRowGrid(ref)
        return false
      })

      this.bindMoustrapTabChange()
    },
    bindMoustrapTabChange() {
      const vm = this
      this.$mousetrap.bindGlobal(['ctrl+['], e => {
        e.preventDefault()
        e.stopPropagation()
        vm.changeTabActive('left')
        return false
      })
      this.$mousetrap.bindGlobal(['ctrl+]'], e => {
        e.preventDefault()
        e.stopPropagation()
        vm.changeTabActive('right')
        return false
      })
      this.$mousetrap.bindGlobal(['ctrl+w'], e => {
        e.preventDefault()
        e.stopPropagation()
        // // eslint-disable-next-line no-underscore-dangle
        // const { tabs } = vm.$taber._vm
        // // eslint-disable-next-line radix
        // const tabsActiveIndex = parseInt(this.$_chain(this.$_pickBy(tabs, { active: true })).keys().first().value())
        // // eslint-disable-next-line no-restricted-globals
        // if (isNaN(tabsActiveIndex)) return false
        // vm.$taber.close({
        //   name: tabs[tabsActiveIndex].name
        // })
        return false
      })
      this.bindMoustrapFocusedMenu()
    },
    bindMoustrapFocusedMenu() {
      const vm = this
      this.$mousetrap.bindGlobal(['ctrl+m'], e => {
        e.preventDefault()
        e.stopPropagation()
        vm.$events.fire('bindMousetrapFocusedMenu')
        return false
      })
    },
    // eslint-disable-next-line no-unused-vars
    changeTabActive(mode) {
      // const vm = this
      // // eslint-disable-next-line no-underscore-dangle
      // const { tabs } = vm.$taber._vm
      // const tabsLength = tabs.length
      // // eslint-disable-next-line radix
      // const tabsActiveIndex = parseInt(this.$_chain(this.$_pickBy(tabs, { active: true })).keys().first().value())
      // let nextTabIndex = null
      // if (mode === 'left') {
      //   nextTabIndex = tabsActiveIndex === 0 ? tabsLength - 1 : tabsActiveIndex - 1
      // } else {
      //   nextTabIndex = tabsActiveIndex === tabsLength - 1 ? 0 : tabsActiveIndex + 1
      // }
      //
      // vm.$taber.open({
      //   name: tabs[nextTabIndex].name
      // })
      return false
    }
  }
})
