import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import aspnet from 'devextreme-aspnet-data-nojquery'
import { stateMerge } from 'vue-object-merge'
import VueEvents from 'vue-events'
import moment from 'moment'
import {
  chain, map, filter, each, values, size
} from 'lodash/core'
// import merge from 'lodash/merge'
// import pickBy from 'lodash/pickBy'

import store from './store'
import App from './App.vue'
import router from './router'
import 'moment/locale/id'
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/v-calendar'

// import RouterTab and styles
// eslint-disable-next-line import/order
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
import 'devextreme/dist/css/dx.common.css'
// import 'devextreme/dist/css/dx.light.css'
// import '@/assets/css/dx.generic.custom-scheme.scss'
import './modules'

moment.locale('id')

Vue.use(VueEvents)
Vue.prototype.$aspnet = aspnet
Vue.prototype.$moment = moment
Vue.prototype.$_map = map
Vue.prototype.$_each = each
Vue.prototype.$_filter = filter
Vue.prototype.$_chain = chain
Vue.prototype.$_values = values
Vue.prototype.$_size = size
// Vue.prototype.$_pickBy = pickBy
// Vue.prototype.$_merge = merge
Vue.prototype.$stateMerge = stateMerge

require('dotenv').config()

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/index.scss')
// require('@/assets/css/dx.generic.custom-scheme.scss')
// require('@/assets/scss/devextreme.scss')

Vue.config.productionTip = false
Vue.use(RouterTab)

/**
 * If it doesn't already exist, register a separate empty vue instance that
 * is attached to the window, we can use it to listen out for and handle
 * any events that may emitted by components...
 */
if (!window.eventHub) {
  window.eventHub = new Vue()
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
